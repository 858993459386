import Events from './Events'

export default class Channel {
  private readonly events = new Events()
  private isChannelPaused = false
  options: IChannelOptions

  constructor(options: IChannelOptions) {
    if (options.isSequence) {
      this.isChannelPaused = options.isSequence
      this.events.isChannelPaused = this.isChannelPaused
    }

    if (options.disableFlush) {
      // @ts-ignore
      delete this.flush
    }

    this.options = options
    this.events.channelOptions = this.options
  }

  subscribe = (eventName: string, callback: (...args: any) => void, context?: any) => {
    this.subscribeHandler(eventName, callback, context, false)
  }

  once = (eventName: string, callback: (args?: any) => void, context: any) => {
    this.subscribeHandler(eventName, callback, context, true)
  }

  unsubscribe = (eventName: string, callback: (args?: any) => void, context: any) => {
    this.events.removeEventListener(eventName, callback, context)
  }

  pause = () => {
    this.isChannelPaused = true
    this.events.isChannelPaused = this.isChannelPaused
  }

  resume = () => {
    this.isChannelPaused = false
    this.events.isChannelPaused = this.isChannelPaused
    this.events.proceed()
  }

  trigger = (eventName: string, args?: any[]) => {
    this.events.trigger(eventName, args)
  }

  execOnEmptyQueue = (cb: any) => {
    this.events.execOnEmptyQueue(cb)
  }

  execOnEmptyQueueOnce = (cb: any) => {
    this.events.execOnEmptyQueueOnce(cb)
  }

  flush() {
    this.events.flush()
  }

  private subscribeHandler(eventName: string, callback: (...args: any) => void, context?: any, once?: boolean) {
    this.events.addEventListener(eventName, callback, context, once)
  }
}
